import { DirectivesModule } from './directives/directives/directives.module';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//ROUTES
import { AppRoutingModule } from './app-routing.module';

//EXTERNAL
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPayPalModule } from 'ngx-paypal';
import { AngularEditorModule } from '@kolkov/angular-editor';

//COMPONENTS
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { BackToTopComponent } from './components/shared/back-to-top/back-to-top.component';
import { MobileCatComponent } from './components/shared/mobile-cat/mobile-cat.component';
import { WhatsappComponent } from './components/shared/whatsapp/whatsapp.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { ModalTermsComponent } from './components/shared/modal-terms/modal-terms.component';
import { FaqComponent } from './components/shared/faq/faq.component';
import { HomeComponent } from './components/home/home.component'
import { SliderComponent } from './components/home/slider/slider.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { CartComponent } from './components/cart/cart.component'
import { ChatComponent } from './components/chat/chat.component'

//SERVICES
import { UserService } from './services/user.service';
import { ProductsService } from './services/products.service';

import { HelpComponent } from './components/help/help.component';

//INTERCEPTORS
import {BaseUrlInterceptor} from './interceptor/base.url.interceptor';
import { MaterialModule } from './material/material.module';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ContactFormComponent } from './components/shared/contact-form/contact-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoresModule } from './components/stores/stores.module';
import { VideoComponent } from './components/shared/video/video.component';
import { SharedModule } from './components/shared/shared.module';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MobileCatComponent,
    FooterComponent,
    BackToTopComponent,
    WhatsappComponent,
    HomeComponent,
    SliderComponent,
    SidebarComponent,
    ModalTermsComponent,
    FaqComponent,
    NewsletterComponent,
    HelpComponent,
    ContactFormComponent,
    VideoComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    CarouselModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgxPayPalModule,
    AngularEditorModule,
    DirectivesModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}
    }),
    NgbModule,
    StoresModule,
    SharedModule
  ],
  providers: [
    UserService,
    Meta,
    { provide: ProductsService, useClass: ProductsService },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
