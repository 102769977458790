import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  constructor() { }

  status = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  toggle() {
    this.status = !this.status;
    this.change.emit(this.status);
  }
  open() {
    this.status = true;
    this.change.emit(this.status);
  }
  close() {
    this.status = false;
    this.change.emit(this.status);
  }
}
