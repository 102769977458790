import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  showSidebar = false;

  @Output() show: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  showSidebarGlobal() {
    this.showSidebar = true;
    this.show.emit(this.showSidebar);
  }

  hideSidebarGlobal() {
    this.showSidebar = false;
    this.show.emit(this.showSidebar);
  }

}
