import { UserService } from './../../../services/user.service';
import { AuthService } from './../../../services/auth.service';
import { LangService } from './../../../services/lang.service';
import { Component, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { MobileService } from './../../../services/mobile.service';
import { CartService } from 'src/app/services/cart.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { QueriesService } from 'src/app/services/queries.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, startWith, debounceTime, switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        display: 'block'
      })),
      state('out', style({
        display: 'none'
      })),
      transition('in => out', animate('100ms ease-in')),
      transition('out => in', animate('100ms ease-out'))
    ])
  ]
})
export class HeaderComponent implements OnInit {

  status = false;
  logged = false;
  showClass = false;
  showClassCats = false;
  mobile: any;
  languages: string[] = ['es', 'en', 'pt', 'zh'];
  userName: string = "";
  roleName: string = "";
  picture: string = "";
  verified: boolean = false;
  profile: boolean = false;
  favorite: string = "";
  lang: string = '';
  sessionLang: string = '';
  searchForm: FormGroup;
  categories: any[] = [];
  environment = environment;
  cart: any;
  hasAllProducts = false;
  user;

  control = new FormControl();
  filteredProducts: Observable<any>;
  productsCounter = 0;
  maxProducts = 0;
  disableSell = false; 

  constructor(private _cart: CartService, private translate: TranslateService, private _queries: QueriesService, private _noti: NotificationsService, private _products: ProductsService, private router: Router, private _mobile: MobileService, private zone: NgZone, private _lang: LangService, private _auth: AuthService, private _user: UserService) {

    const browserLang = translate.getBrowserLang();
    this.lang = this._lang.getLanguage('edm_lang')
    translate.use(this.lang);

    _mobile.change.subscribe(status => this.status = status);
    this.mobile = _mobile;

    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
    _auth.isLogged.subscribe(logged => this.logged = logged);
    _user.userNameEvent.subscribe(userName => this.userName = userName);
    _user.roleNameEvent.subscribe(roleName => this.roleName = roleName);
    _user.pictureEvent.subscribe(picture => this.picture = picture);
    _user.verifiedEvent.subscribe(verified => this.verified = verified);
    _user.profileEvent.subscribe(profile => this.profile = profile);
    _user.favoriteEvent.subscribe(favorite => this.favorite = favorite);
    _cart.cartEvent.subscribe(cart => this.cart = cart);
    
    this._products.getMyPublishedProductsCount(this.sessionLang).subscribe((pc:any)=>{
      this.productsCounter = pc.total;
      this._user.getUser().subscribe((res:any)=>{
        this.maxProducts = res.account.products;
        if (this.productsCounter >= this.maxProducts) {
          this.disableSell = true;
        }
      })
    })
    
    
  }

  ToggleClass() {
    this.mobile.toggle();
  }

  closeMenuMObile() {
    this.mobile.close()
  }

  showFavorites() {
    this.mobile.close()
  }

  @ViewChild('navbarToggler', { static: false }) navbarToggler;
  @ViewChild('userCollapse', { static: false }) userCollapse;
  @ViewChild('categoriesCollapse', { static: false }) categoriesCollapse;
  @ViewChild('navbarTogglerMenu', { static: false }) navbarTogglerMenu: ElementRef;
  @ViewChild('userCollapseMenu', { static: false }) userCollapseMenu: ElementRef;
  @ViewChild('categoriesCollapseMenu', { static: false }) categoriesCollapseMenu: ElementRef;

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

  collapseAll() {
    if (this.logged) {
      if (this.userCollapseMenu.nativeElement.classList.contains('show')) {
        this.userCollapse.nativeElement.click();
      }
    }
    if (this.navbarTogglerMenu.nativeElement.classList.contains('show')) {
      this.navbarToggler.nativeElement.click();
    }
  }

  collapseAllCats() {
    if (this.categoriesCollapseMenu.nativeElement.classList.contains('show')) {
      this.categoriesCollapse.nativeElement.click();
    }
    if (this.navbarTogglerMenu.nativeElement.classList.contains('show')) {
      this.navbarToggler.nativeElement.click();
    }
  }

  changeLanguage(lang: string) {
    this.deleteCookie('edm_lang');
    this.setCookie('edm_lang', lang, 7, '/');
    this._lang.changeLanguage(lang).subscribe((res: any) => {
      if (res.reload) {
        this.zone.runOutsideAngular(function() {
          location.reload();
        });
      }
    });
  }

  searchAll(form) {
    if (form.value != "") {
      this.router.navigate(['/search', form.value]);
    }
  }

  goProduct(form) {
    //    this.router.navigate(['/products/id/' + form._id]);
  }

  navigateProduct(category: string, slug: string) {
    this.router.navigateByUrl(`/products/${category}/${slug}`)
  }

  logout() {
    this._auth.logout().subscribe((res: any) => {
      if (!res.err) {
        this._noti.stopNotiCycle();
        this._queries.stopQueriesCycle();
        this._auth.loggedOutGlobal();
        this.router.navigate(['/']);
      }
    })
  }

  logoutMobile() {
    if (this.logged) {
      if (this.userCollapseMenu.nativeElement.classList.contains('show')) {
        this.userCollapse.nativeElement.click();
      }
    }
    if (this.navbarTogglerMenu.nativeElement.classList.contains('show')) {
      this.navbarToggler.nativeElement.click();
    }
    this._auth.logout().subscribe((res: any) => {
      if (!res.err) {
        this._noti.stopNotiCycle();
        this._auth.loggedOutGlobal();
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit() {
    this.sessionLang = this._lang.getLanguage('edm_lang')
    this._products.getCategoryTypes(this.sessionLang, false).subscribe((res: any) => {
      this.categories = res;
    })
    
    this._cart.getAll(this.sessionLang).subscribe((res: any) => {
      this.cart = res;
      this.cart = this.cart.products.length;
    })

    this.filteredProducts = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      switchMap(value => this._products.searchProducts(this.sessionLang, value, '250', {}))
    )
    this.hasAllProducts;
    this.user = this._auth.getUser();
   
    if (this.user.productCount >= this.user.account.products) {
      console.log(123);
      this.hasAllProducts = true;
    }
  }

  setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  deleteCookie(name) {
    this.setCookie(name, '', -1);
  }


  visibleOptions: boolean[] = [];
  selectedOptionIndex: number | null = null;

  toggleOptions(index: number): void {
    if (this.visibleOptions[index]) {
      this.visibleOptions = [];
    } else {
      this.visibleOptions = this.categories.map((_, i) => i === index);
    }
  }

  isOptionVisible(index: number): boolean {
    return this.visibleOptions[index];
  }

  getDashboardUrl() {
    const baseUrl = environment.dashboardUrl;
    const token = this.user.token;
    return `${baseUrl}/external_auth?token=${token}`;
  }

}
