import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  
  newsletterForm: FormGroup;
  success:boolean = false;
  lang:string = '';

  constructor(private _newsletter:NewsletterService, private _lang:LangService) {
    this.lang = this._lang.getLanguage('edm_lang')
    this.newsletterForm = new FormGroup({
      'mailNewsletter': new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._]+@[a-z0-9]+(\.[a-z]{2,})+")])
    })
  }

  ngOnInit() {
  }

  registerNewsletter(form){
    this.success = false
    this._newsletter.registerNewsletter(form.mailNewsletter, this.lang).subscribe((res:any)=>{
      console.log(res);
      this.success = true;
    })
  }

}
