import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LangService } from 'src/app/services/lang.service';
import { HomeService } from 'src/app/services/home.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})

export class SliderComponent implements OnInit {

  slider:any[] = []
  environment = environment;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    nav: false,
    navText: ['', ''],
    autoplay: true,
    items:1,
    responsive: {
      0: {
        items: 1
      }
    }
  }

  sessionLang:string;
  isMobile:string;
  isBrowser:boolean;
  browserLang:string;
  allowLangs:string[]=[
    'es',
    'en',
    'pt',
    'zh'
  ]

  constructor(private _lang:LangService, private _home:HomeService,@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.browserLang = navigator.language.toLowerCase().split('-')[0];
      this.browserLang = this.allowLangs.find(lang => lang == this.browserLang);
      if(!this.browserLang){
        this.browserLang = 'en';
      }
    }
    
  }

  ngOnInit() {
    this.sessionLang = this._lang.getLanguage('edm_lang')
     
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      console.log(1)
      this.isMobile = 'MOBILE'
    }else{
      console.log(12)      // false for not mobile device
      this.isMobile = 'DESKTOP'
    }
    
    this._home.getSlider(this.sessionLang, this.isMobile).subscribe((s:any)=>{
      this.slider = s.data;
    })

    
  }

}
