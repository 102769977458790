import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  id: string = "1";
  showSidebarProfile: boolean = false;
  profileForm: FormGroup;
  success: boolean = false;
  inProcess: boolean = false;
  sendError: boolean = false;
  map = "";

  user: any;
  uss;
  showContact: boolean = false;
  idContact: string = '';
  mailContact: string = '';
  telContact: string = '';
  logged: boolean = false;

  constructor(private route: ActivatedRoute, private _user: UserService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this._user.getSpecificUser(this.id).subscribe((res: any) => {
        this.user = res;
        this.uss = this._user.getFullCredentials();
        if (this.uss) {
          this.logged = true;
          this.idContact = this.uss._id;
          if (this.uss.contactEmail) {
            this.mailContact = this.uss.contactEmail;
          }
          if (this.uss.phone) {
            this.telContact = this.uss.phone;
          }
          if (this.uss.map) {
            this.map = this.uss.map;
          }
          if (this.uss.error) {
            this.showContact = false;
          } else if (this.user.account.name == 'free') {
            this.showContact = false;
          } else {
            if (this.idContact == this.id || this.uss.role == "SUPER") {
              this.showContact = false;
            } else {
              this.showContact = true;
              this.profileForm = new FormGroup({
                'storeEmail': new FormControl(this.mailContact, [Validators.required, Validators.pattern("^[a-z0-9._]+@[a-z0-9]+(\.[a-z]{2,})+")]),
                'storePhone': new FormControl(this.telContact, [Validators.required]),
                'storeMessage': new FormControl('', [Validators.required])
              })
            }
          }
        } else {
          this.logged = false;
        }

      })
    });
  }

  openSidebarProfile() {
    this.showSidebarProfile = true;
  }

  closeSidebarProfile() {
    this.showSidebarProfile = false;
  }

  sendRequest(form) {
    this.success = false;
    this.sendError = false;
    this.inProcess = true;
    this._user.sendMail(this.user._id, form.storeEmail, form.storePhone, form.storeMessage).subscribe((res: any) => {
      this.success = true;
      this.inProcess = false;
      this.profileForm.reset();
    },
      (error: any) => {
        this.inProcess = false;
        this.sendError = true;
      })
  }

  sendInterestEmail() {
    this.success = false;
    this.sendError = false;
    this.inProcess = true;
    this._user.sendInterestEmail(this.user._id, 'es').subscribe((res: any) => {
      this.success = true;
      this.inProcess = false;
    },
      (error: any) => {
        this.inProcess = false;
        this.sendError = true;
      })
  }

}
