import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-modal-terms',
  templateUrl: './modal-terms.component.html',
  styleUrls: ['./modal-terms.component.scss']
})
export class ModalTermsComponent implements OnInit {

  constructor(private _lang:LangService,private translate:TranslateService) { 
    var lang = this._lang.getLanguage('edm_lang')
    translate.use(lang);
  }

  ngOnInit() {
  }

}
