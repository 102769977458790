import { UserService } from './../../../services/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  contactForm:FormGroup
  success:boolean=false;
  inProcess:boolean=false;
  sendError:boolean = false;
  idContact:string=''
  mailContact:string=''; 
  telContact:string='';
  user:any
  id:string = "1";

  constructor(private _user:UserService) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      'email': new FormControl(this.mailContact, [Validators.required,Validators.pattern("^[a-z0-9._]+@[a-z0-9]+(\.[a-z]{2,})+")]),
      'phone': new FormControl(this.telContact, [Validators.required]),
      'message': new FormControl('', [Validators.required])
    })

    this._user.getUser().subscribe((uss:any)=>{
      this.user=uss;
      this.idContact = uss._id;
      if(uss.contactEmail){
        this.mailContact = uss.contactEmail;
      }
      if(uss.phone){ 
        this.telContact = uss.phone;
      }
      if(uss.error){
        //error
      }else{
        if(this.idContact == this.id || uss.role == "SUPER"){
          //error
        }else{
          //this.showContact = true;
          
        }
      }
    })
  }

  sendRequest(form){
    this.success=false;
    this.sendError= false;
    this.inProcess= true;
    this._user.sendMailEdairy(form.email, form.phone, form.message).subscribe((res:any)=>{
      this.success=true;
      this.inProcess= false;
      this.contactForm.reset();
    },
    (error:any)=>{
      this.inProcess= false;
      this.sendError= true;
    })
  }

}
