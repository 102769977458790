import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges, DoCheck } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterViewInit {

  @Input() isOpen = false;
  @Input() idContent = '';
  @Input() headerText = '';
  @Output() handleOpen = new EventEmitter();
  @Input() withDivider = false;
  open = false;
  
  constructor() { }

  ngAfterViewInit() {
    const element: HTMLElement = document.getElementById('btnOpen' + this.idContent);
    element.setAttribute('data-target', '#' + this.idContent);
    element.setAttribute('aria-controls', this.idContent);
    document.getElementById(this.idContent)?.setAttribute('aria-labelledby', this.idContent + 'Heading' );
    document.getElementById(this.idContent + 'Help')
      .setAttribute('data-parent', '#' + this.idContent + 'Help' );
  }

  onOpen(){
    this.open = !this.open;
    this.handleOpen.emit(this.idContent);
  }

  
  public get icon() : string {
    return `fas fa-chevron-${this.open ? 'up' : 'down'}`;
  }
  

}
