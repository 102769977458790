import { Injectable, Output, EventEmitter  } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  lang = 'es';
  @Output() changeLang: EventEmitter<string> = new EventEmitter();

  constructor(private _http:HttpClient) { }

  getBrowserLang(browserLang:string){
    return this._http.get(`/language/correct-to/${browserLang}`, {withCredentials: true});
  }

  changeLanguage(lang:string){
    return this._http.get(`/language/change-to/${lang}`, {withCredentials: true});
  }

  current(language:string){
    this.changeLang.emit(language);
  }

  getLanguage(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
//                this.changeLang.emit(language);
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }
}
