export const environment = {
  production: false,
//  apiUrl: "http://localhost:4050",
  apiUrl: "https://apidev.edairymarket.com",
  adminUrl: "https://admin.edairymarket.com",
  siteUrl: "https://dev.edairymarket.com",
  local: "",
  bucket: "https://edairymarket-products.s3.amazonaws.com",
  dashboardUrl: "https://devdashboard.edairymarket.com"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
