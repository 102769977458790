import { Injectable, Output, EventEmitter } from '@angular/core';
import { Product } from '../models/product.model';
import { products } from '../models/products';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})

export class ProductsService {

  limit = '9';
  showSteps = true;
  proPercent = 0;
  stepNumber = 0;
  @Output() show: EventEmitter<boolean> = new EventEmitter();
  @Output() percent: EventEmitter<number> = new EventEmitter();
  @Output() step: EventEmitter<number> = new EventEmitter();

  constructor(private _http: HttpClient) {

  }

  stepEmit(step: number) {
    this.stepNumber = step;
    this.step.emit(this.stepNumber);
  }

  percentEmit(percent: number) {
    this.proPercent = percent;
    this.percent.emit(this.proPercent);
  }

  showStepsProduct() {
    this.showSteps = true;
    this.show.emit(this.showSteps);
  }

  hideStepsProduct() {
    this.showSteps = false;
    this.show.emit(this.showSteps);
  }

  getParentCategories(lang: string) {
    return this._http.get(`/api/categories/root/${lang}`);
  }

  // usar este
  getCategories(lang: string) {
    return this._http.get<any[]>(`/api/categories/tree/${lang}`);
  }

  getCategoriesByOwner(lang: string, owner: string) {
    return this._http.get(`/api/categories/full_tree/${lang}?owner=${owner}`);
  }

  getSubCategories(slug: string, lang: string, notApproved?: boolean, searchText?: string) {
    let url = `/api/categories/tree/${slug}/${lang}`
    if (notApproved) {
      url += "?notApproved=true"
    }
    if (searchText) {
      url += '?filters={"text":"' + searchText + '"}';
    }
    return this._http.get<any[]>(url);
  }

  getCategoryTypes(lang: string, orderAlphabetically: boolean) {
    return this._http.get(`/api/categories/type/${lang}/${orderAlphabetically}`);
  }

  getCategory(id: string, lang: string) {
    return this._http.get(`/api/categories/${id}/${lang}`);
  }

  getCategoryBySlug(slug: string, lang: string) {
    return this._http.get(`/api/categories/slug/${slug}/${lang}`);
  }

  createCategoryUser(category) {
    return this._http.post(`/api/categories/usercat`, category)
  }

  getFeatured() {
    return products.filter(product => product.featured);
  }

  getUnities(lang: string) {
    return this._http.get(`/api/units/lang/${lang}`);
  }

  getCurrencies(lang: string) {
    return this._http.get(`/api/currencies/lang/${lang}`);
  }

  getCurrency(id: string, lang: string) {
    return this._http.get(`/api/currencies/${id}/${lang}`);
  }

  createProduct(product: Product) {
    return this._http.post('/api/products', product, { withCredentials: true })
  }

  updateProduct(id: string, product: Product) {
    return this._http.put(`/api/products/${id}`, product, { withCredentials: true })
  }

  addImage(id: string, file: any) {
    return this._http.post(`/api/products/${id}/pictures`, file, { withCredentials: true });
  }

  deleteImage(id: string, idImage: string) {
    return this._http.delete(`/api/products/${id}/pictures/${idImage}`, { withCredentials: true });
  }

  addAttach(id: string, file: any) {
    return this._http.post(`/api/products/${id}/attach`, file, { withCredentials: true });
  }

  deleteAttach(id: string) {
    return this._http.delete(`/api/products/${id}/attach`, { withCredentials: true });
  }

  getMyProducts(lang: string) {
    return this._http.get(`/api/users/me/products/${lang}?limit=${this.limit}`, { withCredentials: true });
  }

  sortMyProducts(baseUrl: string, attr: string, type: number) {
    return this._http.get(`${baseUrl}&sort={"${attr}":${type}}`, { withCredentials: true })
  }

  getMyFeaturedProducts(lang: string) {
    return this._http.get(`/api/users/me/products/${lang}?featured=true&limit=${this.limit}`, { withCredentials: true });
  }

  manageFeatured(id: string, featured: boolean) {
    return this._http.put(`/api/products/${id}`, { featured: featured }, { withCredentials: true })
  }

  activateProduct(id: string) {
    return this._http.put(`/api/products/${id}`, { active: true }, { withCredentials: true })
  }

  deactivateProduct(id: string) {
    return this._http.put(`/api/products/${id}`, { active: false }, { withCredentials: true })
  }

  featureProduct(id: string) {
    return this._http.put(`/api/products/${id}`, { featured: true }, { withCredentials: true })
  }

  unfeatureProduct(id: string) {
    return this._http.put(`/api/products/${id}`, { featured: false }, { withCredentials: true })
  }

  deleteProduct(id: string) {
    return this._http.delete(`/api/products/${id}`, { withCredentials: true })
  }

  getProducts(lang: string) {
    return this._http.get(`/api/products/search/${lang}?state=approved&active=true&limit=${this.limit}`,)
  }

  searchProducts(lang: string, query: string, limit: string, params) {
    let limit2 = (limit || this.limit)
    return this._http.get(`/api/products/search/${lang}?state=approved&active=true&q=${query}&limit=${limit2}`, { params })
  }

  getFeauredProducts(lang: string) {
    return this._http.get(`/api/products/search/${lang}?state=approved&active=true&featured=true&limit=${this.limit}`)
  }

  getProdWithDiscount(lang: string) {
    return this._http.get(`/api/products/search/${lang}?state=approved&active=true&discount=true&limit=${this.limit}`)
  }

  getProdWithFinancing(lang: string) {
    return this._http.get(`/api/products/search/${lang}?state=approved&active=true&financing=true&limit=${this.limit}`)
  }

  getProductsOfUser(lang: string, owner: string, page: any) {
    return this._http.get(`/api/products/search/${lang}?owner=${owner}&state=approved&active=true&limit=${this.limit}&page=${page}`)
  }

  getCategoryProducts(lang: string, slug: string, params, attr: any, type: any) {
    const path = `/api/products/search/${lang}?category=${slug}&state=approved&active=true&limit=${this.limit}`;
    return this._http.get(`${path}&sort={"${attr}":${type}}`, { params })
  }
  
  sortProductsCategory(lang: string, slug: string, params, attr: string, type: number) {
    const path = `/api/products/search/${lang}?category=${slug}&state=approved&active=true&limit=${this.limit}`;
    return this._http.get(`${path}&sort={"${attr}":${type}}`, { params })
  }

  filterCategoryFeatured(lang: string, slug: string) {
    return this._http.get(`/api/products/search/${lang}?category=${slug}&state=approved&active=true&featured=true&limit=${this.limit}`)
  }

  getCatProdWithDiscount(lang: string, slug: string) {
    return this._http.get(`/api/products/search/${lang}?category=${slug}&state=approved&active=true&discount=true&limit=${this.limit}`)
  }

  getCatProdWithFinancing(lang: string, slug: string) {
    return this._http.get(`/api/products/search/${lang}?category=${slug}&state=approved&active=true&financing=true&limit=${this.limit}`)
  }

  

  getCategoryProductsOfUser(lang: string, slug: string, owner: string, page: any) {
    return this._http.get(`/api/products/search/${lang}?category=${slug}&owner=${owner}&state=approved&active=true&limit=${this.limit}&page=${page}`)
  }

  reportProducts(idReporter: string, idProduct: string, message: string) {
    return this._http.post(`/api/reports/product`, { idReporter: idReporter, idProduct: idProduct, message: message }, { withCredentials: true });
  }

  getProduct(id: string, lang: string) {
    return this._http.get(`/api/products/${id}/${lang}`, { withCredentials: true });
  }

  getFeaturedProductsCount(_id: string) {
    return this._http.get(`/api/products/search/es?owner=${_id}&featured=true&active=true&state=approved&limit=1`, { withCredentials: true });
  }

  getMyFeaturedProductsCount(lang: string) {
    return this._http.get(`/api/users/me/products/${lang}?limit=1&featured=true&active=true&state=approved`, { withCredentials: true });
  }

  getMyPublishedProductsCount(lang: string) {
    return this._http.get(`/api/users/me/products/${lang}?limit=1&active=true&state=approved`, { withCredentials: true });
  }

  getProductsSummaryCount() {
    return this._http.get(`/api/users/me/products/stats`, { withCredentials: true })
  }

  myProductsSearch(lang, q: string) {
    return this._http.get(`/api/users/me/products/${lang}?limit=${this.limit}&q=${q}`, { withCredentials: true });
  }

  getRelated(_id: string) {
    return this._http.get(`/api/products/${_id}/related`, { withCredentials: true })
  }

  changePage(baseUrl: string, page: number) {
    return this._http.get(`${baseUrl}&page=${page}`, { withCredentials: true })
  }

  changePrevNext(resultUrl: string) {
    return this._http.get(`${resultUrl}`, { withCredentials: true });
  }

  getFeaturedProducts(lang: string) {
    return this._http.get(`/api/products/search/${lang}?featured=true&populatedCategory=true&active=true`);
  }

}
