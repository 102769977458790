import { AuthService } from './auth.service';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {

  limit = '20';
  userId:string = "";
  userName:string = "";
  roleName:string = "";
  companyName:string = "";
  picture:string = "";
  verified:boolean = false;
  profile:boolean = false;

  @Output() idEvent: EventEmitter<string> = new EventEmitter();
  @Output() userNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() roleNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() companyEvent: EventEmitter<string> = new EventEmitter();
  @Output() pictureEvent: EventEmitter<string> = new EventEmitter();
  @Output() verifiedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() profileEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private http:HttpClient, private _auth:AuthService) {}

  list(id:string){
    return this.http.get(`/api/dealers/${id}`);
  }
  
  add(form){
    return this.http.post('/api/dealers/', form);
  }
  
  delete(id:string){
    return this.http.delete(`/api/dealers/${id}`);
  }

  update(description:string, firstname:string, lastname:string, contactEmail:string, phone:number, company:number, address:string){
    return this.http.put(`/api/dealers/`, {description: description, firstname: firstname, lastname: lastname, contactEmail: contactEmail, phone:phone, company:company, address:address}, {withCredentials: true})
  }
}
