import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private _http:HttpClient) { }

  getReasons(lang:string){
    return this._http.get(`/api/reports/reasons/${lang}`, {withCredentials: true})
  }

  sendUserReport(idReporter:string, idUser:string, idReason:string, message?:string){
    return this._http.post(`/api/reports/user`, {idReporter: idReporter, idUser: idUser,idReason: idReason, message: message}, {withCredentials: true});
  }

  sendProductReport(idReporter:string, idProduct:string, idReason:string, message?:string){
    return this._http.post(`/api/reports/product`, {idReporter: idReporter, idProduct: idProduct,idReason: idReason, message: message}, {withCredentials: true});
  }

}
