import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private meta:Meta,private titleService:Title) { }



  setMetadata(lang:string,data:[]){
    let content=this.getContent(lang,data)
    this.titleService.setTitle(content.title)
    this.meta.addTag({name:"description",content:content.description})
  }


  private getContent(lang:string,data:any[]){
    let langContent=data.find(d=>d.langCode==lang)
    if(langContent){
      return {title:langContent.title,description:langContent.metadata}
    }
    return {title:"eDairyMarket",description:""}
  }
}
