import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion/accordion.component';
import { ProductV2Component } from 'src/app/components/products/product-v2/product-v2.component';
import { PriceProductPipe } from 'src/app/components/products/product-v2/price-product.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../login/login.module';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}
    }),
  ],
  declarations: [AccordionComponent, ProductV2Component, PriceProductPipe],
  exports: [AccordionComponent, ProductV2Component, PriceProductPipe]
})
export class SharedModule { }
