import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap, startWith, debounceTime, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})

export class StoresComponent implements OnInit {

  lang:string = '';
  users:any;
  environment = environment;
  searchForm: FormGroup;
  
  control = new FormControl();
  filtered: Observable <any>;

  constructor(private translate: TranslateService, private _lang:LangService, private _user:UserService,private _sidebar:SidebarService, private titleService:Title,private metaService:Meta) {
    const browserLang = translate.getBrowserLang();
    this.lang = this._lang.getLanguage('edm_lang');
    translate.use(this.lang);
    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
  }

  ngOnInit() {
    var sub=this.translate.get(["STORES.METATITLE","STORES.METADESCRIPTION"]).subscribe(tags=>{
      sub.unsubscribe()
      if(tags["STORES.METATITLE"]){
        this.titleService.setTitle(tags["STORES.METATITLE"])
      }

      if(tags["STORES.METADESCRIPTION"]){
        this.metaService.updateTag(
          {name: 'description', content:tags["STORES.METADESCRIPTION"]}
        );
      }
    })
    this._sidebar.hideSidebarGlobal();
    this._user.getCompanies().subscribe((u:any)=>{
      this.users=u;
      this.pagUsers(u.totalPages)
    })
    
    this.filtered = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      switchMap(value => this._user.searchUsers(value))
    )
  }

  changeUsersPage(baseUrl:string, page:number){
    console.log(baseUrl);
    this._user.changePage(baseUrl, page).subscribe((u:any)=>{
      this.users = u;
      this.pagUsers(u.totalPages)
    })
  }

  changeUsersPrevNext(resultUrl:string){
    this._user.changePrevNext(resultUrl).subscribe((u:any)=>{
      this.users = u;
      this.pagUsers(u.totalPages)
    })
  }

  pagUsers(totalPages:number){
    if(totalPages > 1){
      if(totalPages < 5){
        this.users.pageNumbers = Array(this.users.totalPages).fill(0).map((x,i)=>i);
      }else{
        this.users.pageNumbers = Array(this.users.totalPages).fill(0).map((x,i)=>i);
      }
    }
  }
  
  searchAll(form){
      this._user.searchUsers(form.value).subscribe((u:any)=>{
        this.users=u;
        this.pagUsers(u.totalPages)
      })
  }
  
  goProduct(form){
    console.log(form);
//    this.router.navigate(['/products/id/' + form._id]);
  }
  
}
