import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserService } from './services/user.service';
import { Component, PLATFORM_ID, Inject, NgZone, OnInit } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LangService } from '../app/services/lang.service';
import { AuthService } from './services/auth.service';
import { ProductsService } from './services/products.service';
import { NotificationsService } from './services/notifications.service';
import { QueriesService } from './services/queries.service';
import { environment } from 'src/environments/environment';
import { filter, map, mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'eDairyMarket';
  browserLang: string = 'es';
  isBrowser: Boolean = false;
  lang: string = '';
  private isConsented: boolean = false;
  visibility: boolean = false;

  languages: string[] = ['es', 'en', 'pt', 'zh'];

  constructor(private _queries: QueriesService, private _noti: NotificationsService, private _prod: ProductsService, private _lang: LangService, private zone: NgZone, @Inject(PLATFORM_ID) platformId: Object, private _auth: AuthService, private _user: UserService, private router: Router, private activatedRoute: ActivatedRoute,@Inject(DOCUMENT) private dom) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.browserLang = navigator.language.toLowerCase().split('-')[0];
      this.getBrowserLang(this.browserLang);

      this.setUpLoggedInGlobals()
    }
  }

  getBrowserLang(browserLang: string) {
    if (!this.languages.includes(browserLang)) {
      browserLang = 'en';
    }
    let lang = '';
    let langCookie = this.getCookie('edm_lang');
    if (langCookie == '') {

      console.log(567);
      this.setCookie('edm_lang', browserLang, 7, '/');
      lang = browserLang;

      this._lang.getBrowserLang(lang).subscribe((res: any) => {
        if (res.reload && this.router.url !== "/" && !this.router.url.includes("featured")) {
          this.zone.runOutsideAngular(function () {
            location.reload();
          });
        } else {
          console.log(res);
          this._lang.current(res.lang);
        }
      });

    } else {
      lang = langCookie;
    }
  }

  setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name) {
    this.setCookie(name, '', -1);
  }
  
  setUpLoggedInGlobals() {
    this._auth.isLoggedIn().subscribe((res: any) => {
      if (res.logged) {
        this._auth.loggedInGlobal();
        this._user.getUser().subscribe((res: any) => {
          this._noti.startNotiCycle();
          this._queries.startQueriesCycle();
          let name = `${res.firstname} ${res.lastname}`;
          this._user.userIdGlobal(res._id);
          this._user.userNameGlobal(name);
          this._user.userRoleGlobal(res.role);
          this._user.verifiedGlobal(res.account.verified);
          this._user.companyNameGlobal(res.company);
          this._user.profileGlobal(res.account.profile);
          if (res.picture) {
            this._user.pictureGlobal(res.picture);
          } else {
            this._user.pictureGlobal(environment.apiUrl + '/api/users/picture/notFound.jpg');
          }
        })
      } else {
        this._auth.loggedOutGlobal();
      }
    });
  }


  ngOnInit(): void {

    this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      map(evt => this.activatedRoute),
      map(route => {
        this.setUpLoggedInGlobals()
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    ).pipe(
      mergeMap(route => route.data)
    ).subscribe(data =>{ 
      data.layoutHidden === true ? this.visibility = false : this.visibility = true
      this.updateCanonicalUrl(window.location.href);
    })
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

}
