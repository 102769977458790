import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent implements OnInit {

  windowScrolled: boolean;
  constructor() {}

  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset > 200) {
          this.windowScrolled = true;
      } 
     else if (this.windowScrolled && window.pageYOffset < 200) {
          this.windowScrolled = false;
      }
  }

  ngOnInit() {
  }

}
