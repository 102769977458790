import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LangService } from 'src/app/services/lang.service';
import { UserService } from 'src/app/services/user.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-report-store-modal',
  templateUrl: './report-store-modal.component.html',
  styleUrls: ['./report-store-modal.component.scss']
})
export class ReportStoreModalComponent implements OnInit {

  @ViewChild('closeReportStore', {static: false}) closeReportStore;
  @ViewChild('otherInput', {static: false}) otherInput;

  reasons:string[]=[]

  allowLangs:string[]=[
    'es',
    'en',
    'pt',
    'zh'
  ]

  reason:string = "";
  needsMessage:boolean = false;
  message:string = '';
  index:number;
  sessionLang:string = "";
  errorReport:boolean=false;
  errorOther:boolean=false;
  success:boolean=false;
  isBrowser:boolean=false;
  browserLang:string="";
  sending:boolean=false;
  errorSending:boolean=false;

  @Input() id: string;
  @Input() idReporter: string;
  
  constructor(private _reports:ReportsService, @Inject(PLATFORM_ID) platformId: Object, private _lang:LangService, private _user:UserService) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.browserLang = navigator.language.toLowerCase().split('-')[0];
      this.browserLang = this.allowLangs.find(lang => lang == this.browserLang);
      if(!this.browserLang){
        this.browserLang = 'en';
      }
    }
  }

  ngOnInit() {
    this.sessionLang = this._lang.getLanguage('edm_lang');
    this._reports.getReasons(this.sessionLang).subscribe((r:any)=>{
      this.reasons = r.data;
    })
  }

  changeReason(id:string, needMessage:boolean){
    this.reason = id;
    if(needMessage){
      this.needsMessage = true;
    }else{
      this.needsMessage = false;
    }
  }

  reportStore(){
    this.errorReport = false;
    this.errorOther = false;
    this.success=false;
    this.sending=false;
    this.errorSending=false;
    if(!this.reason){
      this.errorReport = true;
    }else{
      if(this.needsMessage){
        this.errorOther = false;
        this.message = this.otherInput.nativeElement.value;
        if(!this.message){
          this.errorOther = true;
        }
      }
      if(!this.errorReport && !this.errorOther){
        this.sending = true;
        this._reports.sendUserReport(this.idReporter, this.id, this.reason, this.message).subscribe(
          (rep:any)=>{
          if(rep.msg == 'OK'){
            this.reason = '';
            this.success=true;
            this.sending = false;
            this.errorSending = false;
            this.needsMessage = false;
            setTimeout(() => {
              this.closeReportStore.nativeElement.click();
              this.success=false;
            }, 10000);
          }
        },
          (err:any)=>{
            this.sending = false;
            this.errorSending = true;
          }
        )
      }
    }
  }

}
