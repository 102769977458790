import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http:HttpClient) {}
  
  registerNewsletter(email:string, language:string) {
    return this.http.post('/api/newsletter', {language, email});
  }
}
