import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceProduct'
})
export class PriceProductPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value === 'A Convenir' ? value : `$${value}`;
  }

}
