import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { QueriesService } from 'src/app/services/queries.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  logged = false;
  userId = "";
  userName = "";
  roleName:string = "";
  showSidebar = false;
  verified:boolean = false;
  profile:boolean = false;
  companyName = "";
  notCounter:number = 0;
  qCount:number = 0;

  constructor(private translate: TranslateService, private _lang:LangService, private _queries:QueriesService, private _noti:NotificationsService, private _auth:AuthService, private _user:UserService, private _sidebar:SidebarService, private router:Router) {
    translate.use(this._lang.getLanguage('edm_lang'));
    _auth.isLogged.subscribe( logged => this.logged = logged );
    _sidebar.show.subscribe( showSidebar => this.showSidebar = showSidebar );
    _user.idEvent.subscribe( userId => this.userId = userId );
    _user.userNameEvent.subscribe( userName => this.userName = userName );
    _user.companyEvent.subscribe( companyName => this.companyName = companyName );
    _user.verifiedEvent.subscribe( verified => this.verified = verified );
    _user.profileEvent.subscribe( profile => this.profile = profile );
    _noti.notiCount.subscribe( counter => this.notCounter = parseInt(counter));
    _queries.queriesCount.subscribe( counter => this.qCount = parseInt(counter) )
  }

  logout(){
    this._auth.logout().subscribe((res:any)=>{
      if(!res.err){
        this._noti.stopNotiCycle();
        this._queries.stopQueriesCycle();
        this._auth.loggedOutGlobal();
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit() {
    this._user.getUser().subscribe((res:any)=>{
      this.roleName = res.role;
    })
  }

  clearNotifications(){
    this._noti.clearNotifications().subscribe((n:any)=>{
      this.notCounter = 0;
    })
  }

  clearQueries(){
    this._queries.clearQueries().subscribe((n:any)=>{
      this.qCount = 0;
    })
  }

}
