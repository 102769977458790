import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoresRoutingModule } from './stores-routing.module';
import { StoresComponent } from './stores.component';
import { ProfileComponent } from './profile/profile.component';
import { CatalogComponent } from './catalog/catalog.component';
import { DistributorsComponent } from './distributors/distributors.component';
import { StoreComponent } from './store/store.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppModule } from 'src/app/app.module';
import { ReportStoreModalComponent } from './report-store-modal/report-store-modal.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/components/shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  console.log(new TranslateHttpLoader(httpClient));
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    StoresComponent, 
    ProfileComponent, 
    CatalogComponent, 
    StoreComponent, 
    ReportStoreModalComponent, 
    DistributorsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoresRoutingModule,
    RouterModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA0Hprqsm_pA02ld1CyQjMcNB02Wy3IoEY',
      libraries: ['places']
    }),
    SharedModule
  ]
})
export class StoresModule { }
