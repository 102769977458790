import { Component, OnInit , PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LangService } from 'src/app/services/lang.service';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {

  page = 1;
  count = 0;
  tableSize = 9;
  tableSizes = [10, 25, 50, 10];
  currentIndex = -1;

  products:any={}

  categories:any[]=[]

  origins:any[]=[
    {
      city: 'Buenos Aires'
    },
    {
      city: 'Córdoba'
    },
    {
      city: 'Santa Fe'
    }
  ]

  showSidebarCatalog:boolean = false;
  id:string = '';
  isBrowser:boolean;
  browserLang:string;
  allowLangs:string[]=[
    'es',
    'en',
    'pt',
    'zh'
  ]

  slug:string='';
  sessionLang:string='';
  showReport:boolean=false;
  idReporter:string='';
  user;

  constructor(private _user:UserService, private _products:ProductsService, private route:ActivatedRoute, @Inject(PLATFORM_ID) platformId: Object, private _lang:LangService) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.browserLang = navigator.language.toLowerCase().split('-')[0];
      this.browserLang = this.allowLangs.find(lang => lang == this.browserLang);
      if(!this.browserLang){
        this.browserLang = 'en';
      }
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this.slug = params.get("slug");
    })
    
    this.user = this._user.getFullCredentials();
    if(this.idReporter == this.id || this.user.role == "SUPER") {
      this.showReport = false;
      this.idReporter = this.user._id;
    }else{
      this.showReport = true;
    }
    
    this.sessionLang = this._lang.getLanguage('edm_lang')
    this._products.getCategoriesByOwner(this.sessionLang, this.id).subscribe((cats:any)=> {
      this.categories = cats;
      this.categories.forEach(cat => {
        cat.children.sort((a, b) => a.title.localeCompare(b.title))
        cat.children.forEach(child => {
          if(child.children){
            child.children.sort((a, b) => a.title.localeCompare(b.title))
          }
        });
      });
    })
    
    this.list();

  }
  
  list(): void {
    const params = this.getRequestParams(this.page, this.tableSize);
    if(this.slug){
      this._products.getCategoryProductsOfUser(this.sessionLang, this.slug, this.id, this.page).subscribe((products:any)=>{
        this.products = products;
        this.count = products.total;
      })
    }else{
      this._products.getProductsOfUser(this.sessionLang, this.id, this.page).subscribe((products:any)=>{
        this.products = products;
        this.count = products.total;
      })
    }
  }
  
  getRequestParams(page: number, tableSize: number): any {
    let params: any = {};
    params[`_sortDir`] = 'DESC';
    params[`_sortField`] = 'updated_at';
    params[`_page`] = page;
    params[`_perPage`] = tableSize;
    return params;
  }

  openSidebarCatalog(){
    this.showSidebarCatalog = true;
  }

  closeSidebarCatalog(){
    this.showSidebarCatalog = false;
  }

  open(event){
    event.path[1].classList.toggle('open');
  }

  changeCategory(slug:string){
    console.log(123);
    this.page = 1;
    if(slug){
      this._products.getCategoryProductsOfUser(this.sessionLang, slug, this.id, this.page).subscribe((products:any)=>{
        this.products = products;
        this.count = products.total;
      })
    } else{
      this._products.getProductsOfUser(this.sessionLang, this.id, this.page).subscribe((products:any)=>{
        this.products = products;
        this.count = products.total;
      })
    }
  }
  
  onTableDataChange(event){
    this.page = event;
    this.list();
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.list();
  }

}
