import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Time } from '@angular/common';
import { TimeInterval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  limit = '5';
  intervalId: any;

  @Output() notiCount: EventEmitter<string> = new EventEmitter();

  constructor(private _http:HttpClient) {}

  getNotifications(){
    return this._http.get(`/api/notifications?limit=${this.limit}`, {withCredentials: true});
  }

  getNotCounter(){
    return this._http.get(`/api/notifications?limit=1`, {withCredentials: true});
  }

  emitNotiCount() {
    this._http.get(`/api/notifications?limit=1`, {withCredentials: true}).subscribe(
      (res: any) => {
        this.notiCount.emit(res.newNotifications)
      }
    );
  }

  startNotiCycle() {
    this.emitNotiCount()
    this.intervalId = setInterval( () => {
      this.emitNotiCount()
    }, 600000)
  }


  stopNotiCycle() {
    clearInterval(this.intervalId)
  }

  clearNotifications(){
    return this._http.put(`/api/notifications/viewed`, {withCredentials: true});
  }

  changePage(baseUrl:string,page:number){
    return this._http.get(`${baseUrl}&page=${page}`, {withCredentials:true})
  }

  changePrevNext(resultUrl:string){
    return this._http.get(`${resultUrl}`, {withCredentials:true});
  }

}
