import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot,CanActivate, Route } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private _auth:AuthService, private router:Router, private http:HttpClient) {
  }

  canActivate (next:ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean>{
    // console.log(next);
    return new Promise( (ok, err) => {
      this.http.get(`/isLogged`,{withCredentials: true}).subscribe((res:any)=>{
        if(res && res.logged) ok(true)
        else {
          this.router.navigate(['/login']);
          ok(false);
        }
      })
    })
    // if(this._auth.isLoggedIn()){
    //   return true;
    // }else{
    //   this.router.navigate(['/login']);
    //   return false
    // }
  }
  
}
