import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private _http:HttpClient) {}

  getSlider(lang:string, sliderType:string){
    return this._http.get(`/api/home/sliders/${lang}?type=${sliderType}`, {withCredentials: true});
  }

  getHome(lang:string){
    return this._http.get(`/api/home/${lang}`, {withCredentials: true});
  }

}
