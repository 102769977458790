import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';
import { MetadataService } from 'src/app/services/metadata.service';

declare let ga: Function;

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  id:string = "";
  user:any={};

  showSidebarProfile:boolean;
  cover:string = '';
  profile:string = '';
  name:string = '';
  lang:string = '';
  idReporter:string='';
  environment = environment;

  constructor(private translate: TranslateService, private _lang:LangService, private _user:UserService, private router:Router, private route:ActivatedRoute,private _sidebar:SidebarService,private metadataService:MetadataService) {
    const browserLang = translate.getBrowserLang();
    this.lang = this._lang.getLanguage('edm_lang');
    translate.use(this.lang);
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
    this._sidebar.hideSidebarGlobal();
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this._user.getSpecificUser(this.id).subscribe((res:any)=>{
        console.log(res);
        this.user = res;
        this.metadataService.setMetadata(this.lang,this.user.seoData);
      })
    });
    this._user.getUser().subscribe((uss:any)=>{
      // console.log(uss);
      this.idReporter = uss._id;
    })
  }

}
