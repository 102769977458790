import { AuthService } from './auth.service';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  limit = '20';
  userId:string = "";
  userName:string = "";
  roleName:string = "";
  companyName:string = "";
  picture:string = "";
  verified:boolean = false;
  profile:boolean = false;
  favorite:any = "";

  @Output() idEvent: EventEmitter<string> = new EventEmitter();
  @Output() userNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() roleNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() companyEvent: EventEmitter<string> = new EventEmitter();
  @Output() pictureEvent: EventEmitter<string> = new EventEmitter();
  @Output() verifiedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() profileEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() favoriteEvent: EventEmitter<string> = new EventEmitter();

  constructor(private http:HttpClient, private _auth:AuthService) {}

  getUser(){
    return this.http.get(`/api/users/me`, {withCredentials: true});
  }

  getUsers(){
    return this.http.get(`/api/users?roles=user&status=active&limit=${this.limit}`);
  }

  getCompanies(){
    return this.http.get(`/api/users/company?roles=user&status=active&limit=${this.limit}`);
  }

  searchUsers(query:string){
    return this.http.get(`/api/users/company?roles=user&status=active&q=${query}&limit=${this.limit}`)
  }

  getSpecificUser(id:string){
    return this.http.get(`/api/users/${id}`);
  }

  registerUser(item:string){
    return this.http.post('/register', item);
  }

  userExist(token:string){
    return this.http.get(`/api/users/${token}/exists`);
  }

  userExistByEmail(email:string){
    return this.http.get(`/api/users/exist_email/${email}`);
  }

  sendVerificationEmail(email:string, lang:string){
    return this.http.get(`/api/users/send_verify/${email}/${lang}/`);
  }

  changeType(type:string, token?:string){
    return this.http.put(`/api/users/me`, {token: token || undefined, pendingAccountName : type}, {withCredentials: true});
  }

  updateProfilePicture(file:any) {
    return this.http.post("/api/users/picture", file);
  }

  updateProfileBackgroundPicture(file:any){
    return this.http.post("/api/users/background", file);
  }

  updateCoverPicture(file:any){
    return this.http.post("/api/users/cover", file);
  }

  updateProfile(description:string, firstname:string, lastname:string, contactEmail:string, phone:number, company:number, address:string){
    return this.http.put(`/api/users/me`, {description: description, firstname: firstname, lastname: lastname, contactEmail: contactEmail, phone:phone, company:company, address:address}, {withCredentials: true})
  }

  update(item:any){
    return this.http.put(`/api/users/me`, item, {withCredentials: true})
  }

  recoverPassword(mail:string){
    return this.http.post(`/api/users/recover-password`, {email: mail});
  }

  checkTokenPassword(token:string, mail:string){
    return this.http.get(`/api/users/recover-password/${token}?email=${mail}`)
  }

  newPassword(token:string, mail:string, password:string){
    return this.http.post(`/api/users/recover-password/${token}`, {email:mail,password:password})
  }

  userIdGlobal(id:string){
    this.userId = id;
    this.idEvent.emit(this.userId);
  }

  userNameGlobal(name:string){
    this.userName = name;
    this.userNameEvent.emit(this.userName);
  }

  userRoleGlobal(role:string){
    this.roleName = role;
    this.roleNameEvent.emit(this.roleName);
  }

  companyNameGlobal(company:string){
    this.companyName = company;
    this.companyEvent.emit(this.companyName);
  }

  verifiedGlobal(verified:boolean){
    this.verified = verified;
    this.verifiedEvent.emit(this.verified);
  }

  profileGlobal(profile:boolean){
    this.profile = profile;
    this.profileEvent.emit(this.profile);
  }

  pictureGlobal(picture:string){
    this.picture = picture;
    this.pictureEvent.emit(this.picture);
  }

  reportUser(idReporter:string, id:string, message:string){
    return this.http.post(`/api/reports/company`, {idReporter: idReporter, id: id, message: message}, {withCredentials: true});
  }

  changePage(baseUrl:string,page:number){
    return this.http.get(`${baseUrl}&page=${page}`, {withCredentials:true})
  }

  changePrevNext(resultUrl:string){
    return this.http.get(`${resultUrl}`, {withCredentials:true});
  }

  sendMail(to:string, email:string, telephone:string, message:string){
    return this.http.post(`/api/users/profile/contact`, {to: to, email: email, telephone: telephone, message: message}, {withCredentials: true});
  }
  
  sendInterestEmail(to:string, lang:any){
    return this.http.get(`/api/users/interest/${to}/${lang}`, {withCredentials: true});
  }

  sendMailEdairy(email:string, telephone:string, message:string){
    return this.http.post(`/api/users/contact`, { email: email, telephone: telephone, message: message}, {withCredentials: true});
  }

  setCredentials = function (userInfo:any) {
    localStorage["userInfo"] = JSON.stringify(userInfo);
  };

  getCredentials = function () {
    if (localStorage["userInfo"]) {
        var parsedSession = JSON.parse(localStorage["userInfo"]);
        return parsedSession.token;
    } else {
        return '';
    }
  };

  getFullCredentials = function () {
    if (localStorage["userInfo"]) {
        var parsedSession = JSON.parse(localStorage["userInfo"]);
        return parsedSession;
    } else {
        return '';
    }
  };



  getFavorite(){
    return this.http.get(`/api/users/favorites`, {withCredentials:true});
  }

  addFavorite(id:any){
    return this.http.put(`/api/users/favorites`, {'product': id}, {withCredentials: true});
  }

  removeFavorite(id:any){
    return this.http.delete(`/api/users/favorites/${id}`, {withCredentials: true});
  }

//  getCart(resultUrl:string){
//    return this.http.get(`/api/users/cart`, {withCredentials:true});
//  }

  addCartItem(form:any){
    return this.http.put(`/api/users/cart`, form, {withCredentials:true});
  }

  favoriteGlobal(favorites:any){
    this.favorite = favorites;
    this.favoriteEvent.emit(this.favorite);
  }

}
