import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';


@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(private _user:UserService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const session = this._user.getCredentials();
        const url = (req.url.includes("i18n")) ? environment.local : environment.apiUrl;
        if (session) {
            req = req.clone({
            url: url + req.url,
            headers: req.headers.set('x-access-token', `${session}`)
          });
        } else {
            req = req.clone({
            url: url + req.url
          });
        }

        return next.handle(req);
    }
}