import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

// AUTH GUARD
import { AuthGuardService } from './services/auth-guard.service';
import { HelpComponent } from './components/help/help.component';
import { StoresComponent } from './components/stores/stores.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '' },
  {
    path: 'login',
    loadChildren:() => import('./components/login/login.module').then(module => module.LoginModule)
  },
  { path: ':l/login', redirectTo: 'login' },
  {
    path: 'login-adm',
    loadChildren:() => import('./components/login-adm/login-adm.module').then(module => module.LoginAdmModule)
  },
  { path: ':l/login-adm', redirectTo: 'login-adm' },
  {
    path: 'register',
    loadChildren:() => import('./components/register/register.module').then(module => module.RegisterModule)
  },
  { path: ':l/register', redirectTo: 'register' },
  {
    path: 'seller',
    loadChildren:() => import('./components/seller/seller.module').then(module => module.SellerModule),
    canActivate: [AuthGuardService]
  },
  { path: ':l/seller', redirectTo: 'seller' },
  {
    path: 'user',
    loadChildren:() => import('./components/user/user.module').then(module => module.UserModule),
    canActivate: [AuthGuardService]
  },
  { path: ':l/user', redirectTo: 'user' },
  {
    path: 'payment-platforms',
    loadChildren:() => import('./components/payment-platforms/payment-platforms.module').then(module => module.PaymentPlatformsModule),
    canActivate: [AuthGuardService]
  },
  { path: ':l/payment-platforms', redirectTo: 'payment-platforms' },
  {
    path: 'payment',
    loadChildren:() => import('./components/payment/payment.module').then(module => module.PaymentModule)
  },
  { path: ':l/payment', redirectTo: 'payment'},
  {
    path: 'forgot-password',
    loadChildren:() => import('./components/forgot-password/forgot-password.module').then(module => module.ForgotPasswordModule)
  },
  { path: ':l/forgot-password', redirectTo: 'forgot-password'},
  {
    path: 'change-type',
    loadChildren:() => import('./components/change-type/change-type.module').then(module => module.ChangeTypeModule),
    canActivate: [AuthGuardService]
  },
  { path: ':l/change-type', redirectTo: 'change-type' },
  {
    path: 'store',
    loadChildren:() => import('./components/stores/stores.module').then(module => module.StoresModule)
  },
  {
    path: 'stores',component:StoresComponent
  },
  { path: ':l/stores', redirectTo: 'stores'},
  {
    path: 'products',
    loadChildren:() => import('./components/products/products.module').then(module => module.ProductsModule)
  },
  { path: ':l/products', redirectTo: 'products'},
  {
    path: 'product/create',
    loadChildren:() => import('./components/product/create/create.module').then(module => module.CreateModule),
    canActivate: [AuthGuardService]
  },
  { path: ':l/product/create', redirectTo: 'product/create' },
  {
    path: 'product/edit',
    loadChildren:() => import('./components/product/edit/edit.module').then(module => module.EditModule),
    canActivate: [AuthGuardService]
  },
  { path: ':l/product/edit', redirectTo: 'product/create' },
  {
    path: 'search',
    loadChildren:() => import('./components/search/search.module').then(module => module.SearchModule)
  },
  { path: ':l/search', redirectTo: 'search'},
  {
    path: 'pricing',
    loadChildren:() => import('./components/pricing/pricing.module').then(module => module.PricingModule)
  },
  { path: ':l/pricing', redirectTo: 'pricing'},
  {
    path: 'terms',
    loadChildren:() => import('./components/terms/terms.module').then(module => module.TermsModule)
  },
  { path: ':l/terms', redirectTo: 'terms'},
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: ':l/help',
    redirectTo: 'help'
  },
  {
    path: 'featured',
    loadChildren: () => import('./components/featured/featured.module').then(m => m.FeaturedModule)
  },
  {
    path: 'marketing',
    loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule)
  },
  { path: 'cart', loadChildren: () => import('./components/cart/cart.module').then(module => module.CartModule) },
  { path: 'order/chat', loadChildren: () => import('./components/chat/chat.module').then(module => module.ChatModule) },
  { path: '**', redirectTo: '' },
  { path: 'categories', loadChildren: () => import('./components/categories/categories.module').then(m => m.CategoriesModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
