import { AuthService } from './auth.service';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  limit = '20';
  userId:string = "";
  userName:string = "";
  roleName:string = "";
  companyName:string = "";
  picture:string = "";
  verified:boolean = false;
  profile:boolean = false;
  cart;

  @Output() idEvent: EventEmitter<string> = new EventEmitter();
  @Output() userNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() roleNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() companyEvent: EventEmitter<string> = new EventEmitter();
  @Output() pictureEvent: EventEmitter<string> = new EventEmitter();
  @Output() verifiedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() profileEvent: EventEmitter<boolean> = new EventEmitter();

  @Output() cartEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private http:HttpClient, private _auth:AuthService) {}

  getAll(lang:string){
    return this.http.get(`/api/users/cart/${lang}`);
  }

  delete(lang:string, id:string) {
    return this.http.delete(`/api/users/cart/${id}/${lang}`);
  }

  update(lang:string, item:any) {
    return this.http.put(`/api/users/cart/${lang}`, item)
  }

  updateDocuments(item:any) {
    return this.http.put(`/api/users/cart/document`, item)
  }

  createOrder(item:any) {
    return this.http.post(`/api/purchase/checkout`, item)
  }

  cartGlobal(cart){
    this.cart = cart;
    this.cartEvent.emit(this.cart);
  }

  deleteDocument(id:string) {
    return this.http.delete(`/api/users/cart/document/${id}`);
  }

  getFile(id:string) {
    return this.http.get(`/api/users/cart/document/${id}`);
  }
}
