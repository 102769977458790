import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LangService } from 'src/app/services/lang.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { QueriesService } from 'src/app/services/queries.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  logged = false;
  isBrowser:Boolean = false;
  year = new Date().getFullYear();
  lang:string = '';

  constructor(private translate: TranslateService, private _queries:QueriesService, private _noti:NotificationsService, private _auth:AuthService, private _user:UserService, private _lang:LangService, private router:Router) {
    
    const browserLang = translate.getBrowserLang();
    this.lang = this._lang.getLanguage('edm_lang')
    translate.use(this.lang);
    
    _auth.isLogged.subscribe( logged => this.logged = logged );
    _lang.changeLang.subscribe( lang => this.lang = lang );
  }

  ngOnInit() {

  }

  logout(){
    this._auth.logout().subscribe((res:any)=>{
      if(!res.err){
        this._noti.stopNotiCycle();
        this._queries.stopQueriesCycle();
        this._auth.loggedOutGlobal();
        this.router.navigate(['/']);
      }
    })
  }

}
