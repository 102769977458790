import { Product } from './product.model';

export const products:any[]=[
    {
        active: true,
        status: 3,
        id: "00001",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"Solonux",
        categories:[
            "Maquinarias",
            "Otros"
        ],
        price: 10000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Córdoba",
        presentation: "Unidad",
        market: true,
        incoterm: "AR50",
        images:[
            "Featured-Product-01.png"
        ],
        video:"https://www.youtube.com/watch?v=B-bGL7i98P4",
        currency: "U.S. Dollars",
        discount: 20,
        financing:true,
        installments:[
            1,
            2,
            5
        ],
        payment:["CAT"] //CAT, CASH, A CONVENIR
    },
    {
        active: true,
        status: 3,
        id: "00002",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"WESTAFLIA",
        categories:[
            "Maquinarias",
            "Otros"
        ],
        price: 10000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Buenos Aires",
        presentation: "Unidad",
        market: true,
        incoterm: "AR50",
        images:[
            "Featured-Product-02.png"
        ],
        video:"https://www.youtube.com/watch?v=B-bGL7i98P4",
        currency: "U.S. Dollars",
        discount: 0,
        financing: false,
        installments:[
            6,
            12,
            18,
            24
        ],
        payment:["CAT","CASH"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00003",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"Fisher Agro",
        categories:[
            "Maquinarias",
            "Otros"
        ],
        price: 15000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Santa Fe",
        presentation: "Unidad",
        market: true,
        incoterm: "AR50",
        images:[
            "Featured-Product-03.png"
        ],
        video:"https://www.youtube.com/watch?v=B-bGL7i98P4",
        currency: "U.S. Dollars",
        discount: 0,
        financing:false,
        installments:[
            12,
            24,
            48
        ],
        payment:["CAT"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00004",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"DairyCorp",
        categories:[
            "Lácteos",
            "Otros"
        ],
        price: 100,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Córdoba",
        presentation: "Kilos",
        market: true,
        incoterm: "AR25",
        images:[
            "Featured-Product-04.png"
        ],
        video:"https://www.youtube.com/watch?v=v17zo26lAmw",
        currency: "Pesos argentinos",
        discount: 0,
        financing: false,
        installments:[
            1,
            6,
            12
        ],
        payment:["CASH"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00005",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"DairyCorp",
        categories:[
            "Quesos",
            "Otros"
        ],
        price: 10000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Chubut",
        presentation: "Kilos",
        market: false,
        incoterm: "AR25",
        images:[
            "Featured-Product-05.png"
        ],
        video:"https://www.youtube.com/watch?v=T3iuEcj7oxQ",
        currency: "Pesos argentinos",
        discount: 5,
        financing:true,
        installments:[
            1,
            6,
            12
        ],
        payment:["CASH"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00006",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"DairyCorp",
        categories:[
            "Quesos",
            "Otros"
        ],
        price: 10000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Córdoba",
        presentation: "Kilos",
        market: true,
        incoterm: "AR150",
        images:[
            "Featured-Product-06.png"
        ],
        video:"https://www.youtube.com/watch?v=Y7Ns9aoqjgE",
        currency: "U.S. Dollars",
        discount: 0,
        financing:false,
        installments:[
            12,
            24,
            36
        ],
        payment:["CAT", "CASH"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00007",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"DairyCorp",
        categories:[
            "Lácteos",
            "Otros"
        ],
        price: 100,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Córdoba",
        presentation: "Kilos",
        market: true,
        incoterm: "AR25",
        images:[
            "Featured-Product-04.png"
        ],
        video:"https://www.youtube.com/watch?v=v17zo26lAmw",
        currency: "Pesos argentinos",
        discount: 0,
        financing: false,
        installments:[
            1,
            6,
            12
        ],
        payment:["CASH"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00008",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"DairyCorp",
        categories:[
            "Quesos",
            "Otros"
        ],
        price: 10000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Chubut",
        presentation: "Kilos",
        market: false,
        incoterm: "AR25",
        images:[
            "Featured-Product-05.png"
        ],
        video:"https://www.youtube.com/watch?v=T3iuEcj7oxQ",
        currency: "Pesos argentinos",
        discount: 5,
        financing:true,
        installments:[
            1,
            6,
            12
        ],
        payment:["CASH"] //CAT, CASH
    },
    {
        active: true,
        status: 3,
        id: "00009",
        title:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        description:[{
            langCode: "es",
            content: "Lorem Ipsum"
        }],
        brand:"DairyCorp",
        categories:[
            "Quesos",
            "Otros"
        ],
        price: 10000,
        condition: true,
        saler: "eDairyMarket",
        featured: true,
        city: "Córdoba",
        presentation: "Kilos",
        market: true,
        incoterm: "AR150",
        images:[
            "Featured-Product-06.png"
        ],
        video:"https://www.youtube.com/watch?v=Y7Ns9aoqjgE",
        currency: "U.S. Dollars",
        discount: 0,
        financing:false,
        installments:[
            12,
            24,
            36
        ],
        payment:["CAT", "CASH"] //CAT, CASH
    }
]