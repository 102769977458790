import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
//import { MapsAPILoader} from '@agm/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DistributorsService } from 'src/app/services/distributors.service';
//import { } from 'googlemaps';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap, startWith, debounceTime, switchMap, map } from 'rxjs/operators';


@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent {

  @ViewChild('search', {static: false}) searchElementRef: ElementRef;
  public searchControl: FormControl;

  public newCoordinators = new Subject();
  public openWindow = new Subject();

  public lat: number;
  public lng: number;
  public zoom: number;
  public openedWindow: number;
  public id: string;
  public slug: string;
  private geoCoder;

  searchForm: FormGroup;

  control = new FormControl();
  filtered: Observable <any>;

  markers = [];

  constructor(private _user:UserService, private _dis:DistributorsService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.searchControl = new FormControl();
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this.slug = params.get("slug");
    })

    this._dis.list(this.id).subscribe((res:any)=> {
      this.lat = res.data[0].lat;
      this.lng = res.data[0].lng;
      this.zoom = 12;
      this.markers = res.data;
//      this.setCurrentPosition();
//      this.mapApiLoader.load();
    })

    this.filtered = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      switchMap(value => this._dis.list(this.id))
    )
  }

  mapClicked($event) {
    console.log($event);
  }

  clickedMarker(label: string, index: number) {
    console.log(`Clicked the marker: ${label || index}`);
    this.openedWindow = index;
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 12;
      });
    }

    // @Todo: resort the locations
  }

  isInfoWindowOpen(index: number) {
    return this.openedWindow === index;
  }

  openWindowLocation(location, index: number) {
    this.openWindow.next(index);
    this.zoomToNewLocation(location.lat, location.lng);
  }

  distanceToCenter(lat, lng) {
    const R = 6371; // mean radius of earth

    const lat1 = this.toRad(this.lat);
    const lng1 = this.toRad(this.lng);
    const lat2 = this.toRad(lat);
    const lng2 = this.toRad(lng);

    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  toRad(degrees) {
    return degrees * Math.PI / 180;
  }

  zoomToNewLocation(lat: number, lng: number) {
    // set new latitude, longtitude
    this.lat = lat;
    this.lng = lng;

    this.newCoordinators.next({
      lat: this.lat,
      lng: this.lng,
      zoom: 12
    });
  }

  sortLocations() {
    this.markers.sort((a, b) => this.distanceToCenter(a.lat, a.lng) - this.distanceToCenter(b.lat, b.lng));
  }

  searchAll(form) {
    console.log(form);
    return this.markers.filter(item =>
      Object.keys(item).some(
        k =>
          item[k] != null &&
          item[k]
            .toString()
            .toLowerCase()
            .includes(form.toLowerCase())
      )
    );
  }

  goProduct(form){
    console.log(form);
    var index = this.markers.findIndex(obj => obj.name === form);
    this.zoomToNewLocation(this.markers[index].lat, this.markers[index].lng);
  }

}