import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QueriesService {

  limit = '12';
  intervId: any;

  @Output() queriesCount: EventEmitter<string> = new EventEmitter();

  constructor(private _http:HttpClient) { }

  getQueries(_id:string){
    return this._http.get(`/api/queries/search?product=${_id}&limit=10&sort={"createdAt":-1}`, {withCredentials:true});
  }

  postQuery(_id:string, question:string){
    return this._http.post(`/api/products/${_id}/queries`, {question} ,{withCredentials:true});
  }

  getLastReceivedQueries(_id:string){
    return this._http.get(`/api/queries/search?to=${_id}&sort={"_id":-1}&limit=1`, {withCredentials:true});
  }

  getReceivedQueries(_id:string){
    return this._http.get(`/api/queries/search?to=${_id}&sort={"_id":-1}&limit=${this.limit}`, {withCredentials:true});
  }
  
  getLastSendedQueries(_id:string){
    return this._http.get(`/api/queries/search?author=${_id}&sort={"_id":-1}&limit=1`, {withCredentials:true});
  }

  getSendedQueries(_id:string){
    return this._http.get(`/api/queries/search?author=${_id}&sort={"_id":-1}&limit=${this.limit}`, {withCredentials:true});
  }

  sortMyQueries(baseUrl:string, attr:string, type:number){
    return this._http.get(`${baseUrl}&sort={"${attr}":${type}}`, {withCredentials: true})
  }

  getQuery(_id:string){
    return this._http.get(`/api/queries/${_id}`, {withCredentials:true})
  }

  postAnswer(_id:string, answer:string){
    return this._http.post(`/api/products/queries/${_id}/answer`, {answer:answer} , {withCredentials:true})
  }

  searchQueries(q:string){
    return this._http.get(`/api/queries/search?q=${q}&limit=${this.limit}`, {withCredentials: true});
  }

  emitQueriesCount() {
    this._http.get(`/api/queries/search?limit=1`, {withCredentials: true}).subscribe(
      (res: any) => {
        this.queriesCount.emit(res.totalNotViewed)
      }
    );
  }

  startQueriesCycle() {
    this.emitQueriesCount()
    this.intervId = setInterval( () => {
      this.emitQueriesCount()
    }, 600000)
  }


  stopQueriesCycle() {
    clearInterval(this.intervId)
  }

  clearQueries(){
    return this._http.post(`/api/queries/viewed`, {withCredentials: true});
  }

  changePage(baseUrl:string,page:number){
    return this._http.get(`${baseUrl}&page=${page}`, {withCredentials:true})
  }

  changePrevNext(resultUrl:string){
    return this._http.get(`${resultUrl}`, {withCredentials:true});
  }

}
