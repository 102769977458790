import { Injectable, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  logged = false;
  access_token:string = "";
  
  @Output() isLogged: EventEmitter<boolean> = new EventEmitter();

  constructor(private router:Router, private http:HttpClient) {}

  loggedInGlobal() {
    this.logged = true;
    this.isLogged.emit(this.logged);
  }

  loggedOutGlobal() {
    this.logged = false;
    this.isLogged.emit(this.logged);
  }

  login(email:string, password:string){
    return this.http.post('/login', {email, password});
  }
  
  getUserInfo(){
    return this.http.get('/gettoken');
  }
  
  getUser(){
    return JSON.parse(localStorage.getItem("userInfo"));
  }

  logout(){
    localStorage.removeItem("userInfo");
    return this.http.get(`/logout`,{withCredentials: true})
  }

  isLoggedIn(): any{
    return this.http.get(`/isLogged`,{withCredentials: true});
  }

}
