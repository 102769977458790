import { HttpClient } from '@angular/common/http';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LangService } from 'src/app/services/lang.service';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  loading = false;
  sent = false;
  openElement: string = '';
  form = this.formBuilder.group({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.email, Validators.minLength(3)]),
    text: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    check: new FormControl('', [Validators.required, Validators.requiredTrue]),
  })

  en = {
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/BIK9669ScDA?si=cpU_ouPCQO5Y6ocd',
    'Como registrar Tienda': 'https://www.youtube.com/embed/J2NsUmqsL5Q?si=T4FIr4Nq8P_wlZzM',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/_yK32ODNmfg?si=agkbGxyfBIhvkC5H',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/ryr2-_dGdVw?si=IPcaDB30yg6FYPS_'
  }

  es = {
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/8sPA4acG7E4?si=BWFMKDasJE48J40j',
    'Como registrar Tienda': 'https://www.youtube.com/embed/MD5Tpr40FXY?si=7JB8qboK_r3b2wse',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/SDnEJUBkda4?si=CPiafuVUHbxBxD1M',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/pbQnul1BpcA?si=8eCg265gYs691x5h'
  }

  pt = {
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/FpQPu3b_0uM?si=3ZWxu62zX2rEvI10',
    'Como registrar Tienda': 'https://www.youtube.com/embed/nHy8YneNTBE?si=6146R9uegjZADwbJ',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/FpQPu3b_0uM?si=2aBpb2Qpo9l9xovX',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/-D20zFy1Fj8?si=PeWjTMFWEPN-Zwvl'
  }
  
  zh = {
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/BIK9669ScDA?si=cpU_ouPCQO5Y6ocd',
    'Como registrar Tienda': 'https://www.youtube.com/embed/J2NsUmqsL5Q?si=T4FIr4Nq8P_wlZzM',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/_yK32ODNmfg?si=agkbGxyfBIhvkC5H',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/ryr2-_dGdVw?si=IPcaDB30yg6FYPS_'
  }
  isBrowser: boolean;
  browserLang: string;
  allowLangs: string[] = [
    'es',
    'en',
    'pt',
    'zh'
  ];
  videos;
  lang;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private _lang: LangService) {
      
    this.lang = this._lang.getLanguage('edm_lang')
    this.videos = this[this.lang];
    console.log(this.videos);
  }

  ngOnInit() {
  }

  open(element: string) {
    this.openElement = element;
  }

  async onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    try {
      await this.http.post('/contact', this.form.value).toPromise();
      this.sent = true;
    } catch (error) {

    } finally {
      this.loading = false;
    }
  }


  isInvalid(controlName: string): boolean {
    return this.form.controls[controlName].invalid && this.form.controls[controlName].touched;
  }


}
