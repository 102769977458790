import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/user.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { QueriesService } from 'src/app/services/queries.service';
import { LangService } from 'src/app/services/lang.service';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  sendVerificationEmailForm: FormGroup;
  errorMsg: boolean = false;
  emailSended: boolean = false;
  emailVefified: boolean = false;
  emailSendedError: boolean = false;
  errorAccountMsg: boolean = false;
  errorNewPasswordMsg: boolean = false;
  showRegisterComplete: boolean = false;
  requestType: string;
  redirectUri: string;
  externalToken: string;
  isBrowser: boolean;
  browserLang: string;
  sessionLang: string;
  lang: string = '';
  allowLangs: string[] = [
    'es',
    'en',
    'pt',
    'zh'
  ];
  showEmailField = false;

  constructor(@Inject(PLATFORM_ID) platformId: Object, private translate: TranslateService, private formBuilder: FormBuilder, private _lang: LangService, private _queries: QueriesService, private _noti: NotificationsService, private titleService: Title, private _auth: AuthService, private _user: UserService, private _sidebar: SidebarService, private router: Router, private route: ActivatedRoute, private metaService: Meta) {
    this.loginForm = new FormGroup({
      'mail': new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._]+@[a-z0-9]+(\.[a-z]{2,})+")]),
      'password': new FormControl('', Validators.required)
    })

    this.sendVerificationEmailForm = this.formBuilder.group({
      'mail': new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._]+@[a-z0-9]+(\.[a-z]{2,})+")]),
    });

    this.isBrowser = isPlatformBrowser(platformId);
    const browserLang = translate.getBrowserLang();
    this.lang = this._lang.getLanguage('edm_lang')
    translate.use(this.lang);
    this._lang.current('en');
    if (this.isBrowser) {
      this.browserLang = navigator.language.toLowerCase().split('-')[0];
      this.browserLang = this.allowLangs.find(lang => lang == this.browserLang);
      if (!this.browserLang) {
        this.browserLang = 'en';
        console.log(123);

      }
    }
  }

  ngOnInit() {

    var sub = this.translate.get(["LOGIN.METATITLE", "LOGIN.METADESCRIPTION"]).subscribe(tags => {
      sub.unsubscribe()
      if (tags["LOGIN.METATITLE"]) {
        this.titleService.setTitle(tags["LOGIN.METATITLE"])
      }

      if (tags["LOGIN.METADESCRIPTION"]) {
        this.metaService.updateTag(
          { name: 'description', content: tags["LOGIN.METADESCRIPTION"] }
        );
      }

    })
    this.titleService.setTitle('eDairyMarket | Login');
    this._sidebar.hideSidebarGlobal();
    this.sessionLang = this._lang.getLanguage('edm_lang')
    this.route.paramMap.subscribe(params => {
      this.requestType = params.get("type");
      this.redirectUri = params.get("url");
      this.externalToken = params.get("token");
      console.log(this.externalToken);
    })
    this._user.getUser().subscribe(
      (u: any) => {
        console.log(u)
        if (u != null) {
          this.router.navigate([`/user/${this.redirectUri}`]);
        }
      },
      (error: any) => {
        console.log(error);
      })
    localStorage.clear();
  }

  login(form) {
    this._auth.login(form.mail, form.password).subscribe(
      (res: any) => {
        this.errorMsg = false;
        this.errorAccountMsg = false;
        this.errorNewPasswordMsg = false;

        if (res.action == 'new password') {
          this.errorNewPasswordMsg = true;
          return;
        }

        if (res.status != 'active') {
          this.errorAccountMsg = true;
          return;
        }

        this._user.setCredentials(res);
        this._auth.loggedInGlobal();
        let name = `${res.firstname} ${res.lastname}`;
        this._user.userIdGlobal(res._id);
        this._user.userNameGlobal(name);
        this._user.userRoleGlobal(res.role);
        this._user.verifiedGlobal(res.account.verified);
        this._user.companyNameGlobal(res.company);
        this._user.profileGlobal(res.account.profile);

        this._user.getUser().subscribe((res: any) => {
          this._noti.startNotiCycle();
          this._queries.startQueriesCycle();
          if (res.picture) {
            this._user.pictureGlobal(res.picture);
          } else {
            this._user.pictureGlobal(environment.apiUrl + '/api/users/picture/notFound.jpg');
          }
          if (res.role != 'SUPER') {
            if (this.requestType) {
              localStorage.setItem('type', this.requestType);
              this.router.navigate(['/payment-platforms']);
            } else {
              if (this.redirectUri) {
                this.router.navigate([`/`]);
              } else {
                this.router.navigate(['/']);
              }
            }
          } else {
            this.router.navigate(['/']);
          }
        })
      },
      (err: any) => {
        this.errorMsg = true;
      }
    );
  }

  sendEmail(form) {
    this.emailSended = false;
    this.emailVefified = false;
    this.emailSendedError = false;
    this._user.sendVerificationEmail(form.mail, this.sessionLang).subscribe(
      (res: any) => {
        if (res.msg == 'verified email') {
          this.emailVefified = true;
        } else {
          this.emailSended = true;
        }
      },
      (err: any) => {
        console.log(err)
        this.emailSendedError = true;
      }
    )
  }

}
